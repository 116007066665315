<template>
    <div class="w-4/6 2xl:w-3/6 bg-slate-700/60 flex flex-col">
        <div class="sticky top-[65px] shadow-lg z-20">
            <div class="bg-slate-800 w-full flex flex-row items-center justify-center border-b border-slate-900/30">
                <a href="#" class="font-medium text-white after:w-full after:h-px after:bg-indigo-500 after:absolute after:-bottom-[1px] after:left-0 relative duration-150 px-5 py-2">Личная</a>
                <a href="#" class="text-slate-300 hover:text-white after:w-full after:h-px after:bg-transperent hover:after:bg-indigo-500 after:absolute after:-bottom-[1px] after:left-0 relative duration-300 after:duration-150 px-5 py-2">Глобальная</a>
            </div>
            <div class="w-full bg-slate-800 flex flex-row justify-center space-x-3 border-b border-slate-900/30 py-3">
                <a href="#" class="bg-gradient-to-r from-indigo-500 to-purple-500 text-white rounded-full duration-150 px-4 py-1">New</a>
                <a href="#" class="hover:bg-slate-700/50 text-slate-300 rounded-full duration-150 px-4 py-1">🔥Hot</a>
                <a href="#" class="hover:bg-slate-700/50 text-slate-300 rounded-full duration-150 px-4 py-1">🚀Top</a>
                <a href="#" class="hover:bg-slate-700/50 text-slate-300 rounded-full duration-150 px-4 py-1">Controvertial</a>
                <a href="#" class="hover:bg-slate-700/50 text-slate-300 rounded-full duration-150 px-4 py-1">Region</a>
                <a href="#" class="hover:bg-slate-700/50 text-slate-300 rounded-full duration-150 px-4 py-1">Time</a>
            </div>
        </div>
        <div class="flex flex-col space-y-6 my-6 px-8">
            <div class="group w-full h-auto bg-slate-800 hover:bg-slate-800/90 rounded-lg shadow-md border border-slate-900/30 duration-150 py-6">
                <div class="flex flex-row items-center mb-5 px-8">
                    <div class="w-12 h-12 flex items-center justify-center rounded-full overflow-hidden mr-3">
                        <img class="w-full h-full" src="../assets/user_1.jpg" />
                    </div>
                    <div class="flex flex-col">
                        <router-link to="/profile" class="text-slate-300 hover:text-white font-medium duration-150">IDOS GAMES</router-link>
                        <span class="text-xs text-slate-500">1 минуту назад</span>
                    </div>
                    <div class="ml-auto">
                        <a href="#" class="bg-indigo-500 hover:bg-indigo-600 text-white text-xs font-medium flex flex-row items-center rounded duration-150 px-2 py-1">
                            <svg class="w-3 h-3 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"></path></svg>
                            Подписаться
                        </a>
                    </div>
                </div>
                <div class="flex flex-col space-y-2 px-8 mb-5">
                    <p class="text-xl font-light text-slate-300 group-hover:text-white duration-150 mb-2">iDos Games is a team of indie-game developers from Kazakhstan. We are creating the metaverse of P2E games to give every player the opportunity to play, have fun and earn crypto at the same time.</p>
                    <img class="w-full" src="../assets/feed_1.jpg" />
                </div>
                <div class="flex flex-row space-x-5 px-8">
                    <div class="flex flex-row items-center space-x-2 duration-150">
                        <a href="#" class="text-slate-500 hover:text-white">
                            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                        </a>
                        <span class="text-slate-500">999+</span>
                        <a href="#" class="text-slate-500 hover:text-white">
                            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                        </a>
                    </div>
                    <a href="#" class="flex flex-row items-center text-slate-500 hover:text-white duration-150">
                        <svg class="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"></path></svg>
                        100
                    </a>
                    <a href="#" class="flex flex-row items-center text-slate-500 hover:text-white duration-150">
                        <svg class="w-4 h-4 scale-x-[-1] scale-y-[1]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"></path></svg>
                    </a>
                    <a href="#" class="flex flex-row items-center text-slate-500 hover:text-white duration-150">
                        <svg class="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path>
                        </svg>
                        1М
                    </a>
                </div>
            </div>
            <div class="group w-full h-auto bg-slate-800 hover:bg-slate-800/90 rounded-lg shadow-md hover:shadow-lg border border-slate-900/30 duration-150 py-6">
                <div class="flex flex-row items-center mb-5 px-8">
                    <div class="w-12 h-12 flex items-center justify-center rounded-full overflow-hidden mr-3">
                        <img class="w-full h-full" src="../assets/profile_image1.png" />
                    </div>
                    <div class="flex flex-col">
                        <router-link to="/profile" class="text-slate-300 hover:text-white font-medium duration-150">NBA TOP SHOT</router-link>
                        <span class="text-xs text-slate-500">59 минут назад</span>
                    </div>
                </div>
                <div class="flex flex-col space-y-2 px-8 mb-5">
                    <p class="text-xl font-light text-slate-300 group-hover:text-white duration-150">2022 NBA FINALS TOP SHOT PACK DROPS: EVERYTHING YOU NEED TO KNOW</p>
                    <img class="w-full" src="../assets/profile_post_1.jpg" />
                </div>
                <div class="flex flex-row space-x-5 px-8">
                    <div class="flex flex-row items-center space-x-2 duration-150">
                        <a href="#" class="text-slate-500 hover:text-white">
                            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                        </a>
                        <span class="text-slate-500">445</span>
                        <a href="#" class="text-slate-500 hover:text-white">
                            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                        </a>
                    </div>
                    <a href="#" class="flex flex-row items-center text-slate-500 hover:text-white duration-150">
                        <svg class="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"></path></svg>
                        4K
                    </a>
                    <a href="#" class="flex flex-row items-center text-slate-500 hover:text-white duration-150">
                        <svg class="w-4 h-4 scale-x-[-1] scale-y-[1]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"></path></svg>
                    </a>
                    <a href="#" class="flex flex-row items-center text-slate-500 hover:text-white duration-150">
                        <svg class="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path>
                        </svg>
                        154K
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>
