<template>
    <aside class="w-auto h-full flex flex-row flex-none fixed">
        <div class="w-[65px] bg-slate-800 border-r border-slate-900/30">
            <nav class="w-full flex flex-col items-center list-none space-y-4 mt-4">
                <li class="border-b border-slate-900/30 pb-5">
                    <div v-if="$route.name != 'profile'" class="w-12 h-12 flex border-indigo-600 border-4 rounded-full overflow-hidden duration-300">
                        <img src="https://lh3.googleusercontent.com/tA14T-OUudFYlfxfqwBVXFuxpL11Ha2g43cQgCATMhVZwK_4IeTBWzO4sOrfnD8rNYf7hetxBkhTuHu85TMFKvdfbSFjXDNJ8Afx7w=w600" />
                    </div>
                    <router-link v-else to="/" class="w-12 h-12 flex border-gray-700 hover:border-indigo-600 border-4 rounded-full overflow-hidden duration-300">
                        <img src="https://lh3.googleusercontent.com/tA14T-OUudFYlfxfqwBVXFuxpL11Ha2g43cQgCATMhVZwK_4IeTBWzO4sOrfnD8rNYf7hetxBkhTuHu85TMFKvdfbSFjXDNJ8Afx7w=w600" />
                    </router-link>
                </li>
                <li>
                    <div v-if="$route.name === 'profile'" class="w-12 h-12 flex border-indigo-600 border-4 rounded-full overflow-hidden duration-300">
                        <img src="../assets/profile_image1.png" />
                    </div>
                    <router-link v-else to="/profile" class="w-12 h-12 flex border-gray-700 hover:border-indigo-600 border-4 rounded-full overflow-hidden duration-300">
                        <img src="../assets/profile_image1.png" />
                    </router-link>
                </li>
                <li>
                    <a href="#" class="w-12 h-12 flex items-center justify-center text-gray-500 hover:text-gray-400 border-gray-700 hover:border-indigo-600 border-4 rounded-full overflow-hidden duration-300">
                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                    </a>
                </li>
            </nav>
        </div>
        <div class="w-[260px] bg-slate-800 flex flex-col border-r border-slate-900/30">
            <div class="flex flex-row items-center border-b border-slate-900/30 px-4 py-3">
                <div class="w-full h-12 flex flex-col justify-center">
                    <div class="text-slate-300 text-2xl font-semibold">@nikita26.08</div>
                    <div class="text-slate-500 text-xs">Статус пользователя</div>
                </div>
                <button class="bg-transparet hover:bg-slate-700/50 w-8 h-8 flex-none flex items-center text-slate-500 hover:text-slate-400 rounded justify-center outline-none duration-150">
                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                </button>
            </div>
            <nav class="list-none overflow-y-auto">
                <li>
                    <router-link to="/" :class="[$route.name === 'home' ? 'bg-gradient-to-r from-indigo-500 to-purple-500 text-white font-semibold' : 'hover:bg-slate-700/50 text-slate-300']" class="flex flex-row items-center px-3 py-3 duration-150">
                        <svg class="w-5 h-5 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path></svg>
                        Лента
                    </router-link>
                </li>
                <li v-if="$route.name === 'profile'">
                    <router-link to="/profile" :class="[$route.name === 'profile' ? 'bg-gradient-to-r from-indigo-500 to-purple-500 text-white font-semibold' : 'hover:bg-slate-700/50 text-slate-300']" class="flex flex-row items-center px-3 py-3 duration-150">
                        <svg class="w-5 h-5 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"></path></svg>
                        Главная страница
                    </router-link>
                </li>
                <li v-if="$route.name != 'profile'">
                    <a href="#" class="hover:bg-slate-700/50 flex flex-row items-center text-slate-300 px-3 py-3 duration-150">
                        <svg class="w-5 h-5 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"></path></svg>
                        Подписки
                    </a>
                </li>
                <li>
                    <a href="#" class="hover:bg-slate-700/50 flex flex-row items-center text-slate-300 px-3 py-3 duration-150">
                        <svg class="w-5 h-5 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
                        Подписчики
                    </a>
                </li>
                <li v-if="$route.name != 'profile'">
                    <a href="#" class="hover:bg-slate-700/50 flex flex-row items-center text-slate-300 px-3 py-3 duration-150">
                        <svg class="w-5 h-5 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path></svg>
                        Друзья
                    </a>
                </li>
                <li>
                    <a href="#" class="hover:bg-slate-700/50 flex flex-row items-center text-slate-300 px-3 py-3 duration-150">
                        <svg class="w-5 h-5 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg>
                        Создать пост
                    </a>
                </li>
                <li>
                    <a href="#" @click="menu_channels_toggle" class="hover:bg-slate-700/50 flex flex-row items-center text-slate-300 border-b border-slate-900/30 px-3 py-3 duration-150">
                        <svg class="w-5 h-5 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"></path></svg>
                        Каналы
                        <div class="ml-auto text-slate-500 hover:text-slate-300">
                            <svg v-if="menu_collaps" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                            <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                        </div>
                    </a>
                    <div :class="[menu_collaps ? 'flex' : 'hidden']" class="flex-col duration-150">
                        <ul class="list-none border-b border-slate-900/30">
                            <li>
                                <a @click="menu_text_toggle" href="#" class="flex flex-row items-center text-slate-300 hover:text-white px-3 py-3 duration-150">
                                    Text channels
                                    <div class="ml-auto text-slate-500 hover:text-slate-300">
                                        <svg v-if="menu_text_collaps" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                                        <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                                    </div>
                                </a>
                                <ul :class="[menu_text_collaps ? 'flex' : 'hidden']" class="flex-col list-none duration-150">
                                    <li>
                                        <router-link to="/message" class="flex flex-row items-center text-slate-300 hover:text-white px-3 py-3 duration-150">
                                            <svg class="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14"></path></svg>
                                            channel 1
                                            <span class="w-5 h-5 bg-indigo-500 flex items-center justify-center text-xs text-white rounded-full ml-auto">2</span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/message" class="flex flex-row items-center text-slate-300 hover:text-white px-3 py-3 duration-150">
                                            <svg class="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14"></path></svg>
                                            channel 2
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <ul class="list-none border-b border-slate-900/30">
                            <li>
                                <a @click="menu_video_toggle" href="#" class="flex flex-row items-center text-slate-300 hover:text-white px-3 py-3 duration-150">
                                    Video channels
                                    <div class="ml-auto text-slate-500 hover:text-slate-300">
                                        <svg v-if="menu_video_collaps" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                                        <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                                    </div>
                                </a>
                                <ul :class="[menu_video_collaps ? 'flex' : 'hidden']" class="flex-col list-none duration-150">
                                    <li>
                                        <router-link to="/message" class="flex flex-row items-center text-slate-300 hover:text-white px-3 py-3 duration-150">
                                            <svg class="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"></path></svg>
                                            v. channel 1
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/message" class="flex flex-row items-center text-slate-300 hover:text-white px-3 py-3 duration-150">
                                            <svg class="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"></path></svg>
                                            v. channel 2
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <ul class="list-none border-b border-slate-900/30">
                            <li>
                                <a @click="menu_personal_toggle" href="#" class="flex flex-row items-center text-slate-300 hover:text-white px-3 py-3 duration-150">
                                    Personal messages
                                    <div class="ml-auto text-slate-500 hover:text-slate-300">
                                        <svg v-if="menu_personal_collaps" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"></path></svg>
                                        <svg v-else class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                                    </div>
                                </a>
                                <ul :class="[menu_personal_collaps ? 'flex' : 'hidden']" class="flex-col list-none duration-150">
                                    <li>
                                        <router-link to="/message" class="flex flex-row items-center text-slate-300 hover:text-white px-3 py-3 duration-150">
                                            <svg class="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                            user 1
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link to="/message" class="flex flex-row items-center text-slate-300 hover:text-white px-3 py-3 duration-150">
                                            <svg class="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                            user 2
                                            <span class="w-5 h-5 bg-indigo-500 flex items-center justify-center text-xs text-white rounded-full ml-auto">5</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </li>
            </nav>
        </div>
    </aside>
</template>
<script>
import { ref } from 'vue'

export default {
    setup() {
        const menu_collaps = ref(true)
        const menu_text_collaps = ref(true)
        const menu_video_collaps = ref(true)
        const menu_personal_collaps = ref(true)

        const menu_channels_toggle = () => (menu_collaps.value = !menu_collaps.value)
        const menu_text_toggle = () => (menu_text_collaps.value = !menu_text_collaps.value)
        const menu_video_toggle = () => (menu_video_collaps.value = !menu_video_collaps.value)
        const menu_personal_toggle = () => (menu_personal_collaps.value = !menu_personal_collaps.value)

        return { menu_collaps, menu_channels_toggle, menu_text_collaps, menu_text_toggle, menu_video_collaps, menu_video_toggle, menu_personal_collaps, menu_personal_toggle }
    },
}
</script>
