<template>
    <header class="w-full bg-slate-800 border-b border-slate-900/30 sticky top-0 px-8 py-3 z-30">
        <div class="flex flex-row items-center -mx-4">
            <div class="w-1/5 flex flex-row items-center px-4">
                <a class="group text-white font-semibold text-2xl flex flex-row items-center no-underline relative" href="#">
                    <div class="w-4 h-4 flex items-center outline-indigo-600 rounded-full outline-double outline-4 group-hover:scale-105 mr-3 relative duration-300">
                        <span class="w-4 h-4 blur-sm outline-indigo-600 rounded-full outline-double outline-4 outline-offset-2 absolute top-0 left-0 group-hover:scale-125 duration-300"></span>
                        <div class="w-16 h-10 blur-xl bg-gradient-to-r from-indigo-500 to-purple-500 opacity-60 absolute -z-10 left-5"></div>
                    </div>
                    <span class="relative z-10">NEON</span>
                </a>
            </div>
            <div class="w-[450px] flex flex-row items-center px-4">
                <div class="w-full flex flex-row items-center relative">
                    <svg class="w-5 h-5 text-slate-500 -mr-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    <input type="search" class="w-full bg-slate-700/30 focus:bg-slate-700/50 text-slate-400 placeholder:text-slate-500 outline-none rounded-md pl-10 pr-3 py-2 duration-150" placeholder="Поиск..." />
                </div>
            </div>
        </div>
    </header>
    <div class="w-full flex flex-row">
        <Aside />
        <main class="w-full h-auto bg-slate-900 flex flex-row ml-[325px] relative">
            <router-view />
        </main>
    </div>
</template>
<script>
import Aside from './components/Aside'

export default {
    components: { Aside },
}
</script>
